import React, { FC, useEffect } from 'react';
import { graphql } from 'gatsby';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import loadable from '@loadable/component';
import { toBoolean } from 'utils/stringUtils/stringUtils';
import Layout from 'layout/Layout';
import Seo from 'common/Seo';
import PageSchema from 'common/PageSchema';
import Banner from 'common/Banner';
import Carousel from 'common/Carousel';
import ProductsCategoryList from 'components/Products/ProductsCategoryList';
import './homePage.scss';
import Recommendations from 'components/Recommendations';
import tryInteractSnippet from 'components/TryInteract';
import { removeElementById } from 'utils/browser';

const HomePage: FC<{ data: HomepageTypes.HomepageType }> = ({
  data: {
    homepageSettings: { nodes },
    homepageMainBanner: { nodes: homepageMainBannerNodes },
    homepageBlocks: { nodes: homepageBlocksNodes },
    promoBanners,
    salsifyHomeProducts,
  },
}) => {
  const {
    seoProps: { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs },
    langProps: { urls, lang },
  } = nodes[0];
  const {
    promoPanel,
    productsCategoryList,
    mainCarousel,
    findYourVeetTool,
    recommendations,
  } = homepageBlocksNodes[0];

  const promoPanelProps = {
    promoPanel: {
      ...promoPanel,
      promoBanners,
    },
    lang,
  };

  const {
    content,
    bannerVariant,
    imageAsBackground,
    imageAsBackgroundAlt,
    withWrapper,
    button,
  } = homepageMainBannerNodes[0];

  const PromoPanel = loadable(() => import('components/PromoPanel'));
  const SignUpBanner = loadable(() => import('components/SignUpBanner'));

  useEffect(() => {
    if (findYourVeetTool !== null && findYourVeetTool.tryInteractId) {
      tryInteractSnippet(findYourVeetTool.tryInteractId);
    }

    return () => {
      removeElementById('tryInteract');
    };
  }, []);

  return (
    <Layout headerTransparent langProps={{ urls, lang }} className="home-page">
      <Seo
        {...{
          title: seoMetaTitle,
          description: seoMetaDescription,
          keywords: seoMetaKeywords,
          externalHreflangs: seoExternalHreflangs,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      {mainCarousel ? (
        <Carousel
          {...{
            carouselImages: mainCarousel,
            className: 'main-carousel',
          }}
        />
      ) : (
        <Banner
          {...{
            lazyload: false,
            content,
            bannerVariant,
            button,
            imageAsBackground,
            imageAsBackgroundAlt,
            isWithWrapper: toBoolean(withWrapper),
          }}
        />
      )}
      {productsCategoryList !== null ? (
        <ProductsCategoryList {...{ productsCategoryList }} />
      ) : null}
      {recommendations ? (
        <Recommendations
          recommendations={recommendations}
          productNodes={salsifyHomeProducts.nodes}
        />
      ) : null}
      {findYourVeetTool !== null && findYourVeetTool.findYourVeetTitle ? (
        <h2 className="products-category__title">{findYourVeetTool.findYourVeetTitle}</h2>
      ) : null}
      {findYourVeetTool !== null && findYourVeetTool.tryInteractId ? (
        <div className="findYourVeet" id={`interact-${findYourVeetTool.tryInteractId}`} />
      ) : null}
      <PromoPanel {...promoPanelProps} />
      <SignUpBanner {...{ lang }} />
    </Layout>
  );
};

export const query = graphql`
  query($promoArticleTags: [Int], $promoArticlesBannerLimit: Int, $lang: String) {
    salsifyHomeProducts: allSalsifyProducts(filter: { langProps: { lang: { eq: $lang } } }) {
      nodes {
        productTitle
        productTitleWithSize
        url
        productSize
        productImage
        productEAN
        localProductImage {
          childImageSharp {
            fixed {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        variantProductCards {
          title
          image
          size
          titleShort
          titleWithSize
          url
          ean
        }
      }
    }
    homepageSettings: allHomepageSettings(filter: { langProps: { lang: { eq: $lang } } }) {
      nodes {
        seoProps {
          seoMetaDescription
          seoMetaKeywords
          seoMetaTitle
          seoExternalHreflangs {
            key
            value
          }
        }
        langProps {
          urls {
            ...languageUrls
          }
          lang
        }
      }
    }
    homepageMainBanner: allHomepageMainBanner(filter: { lang: { eq: $lang } }) {
      nodes {
        content
        bannerVariant
        withWrapper
        button {
          ariaLabel
          text
          withReverse
          link {
            url
          }
        }
        imageAsBackground {
          fallbackUrl
          fluid {
            srcSet
            originalImg
            base64
          }
          variants {
            url
            type
            fallbackQuery
            alias
          }
        }
        imageAsBackgroundAlt
      }
    }
    homepageBlocks: allHomepageBlocks(filter: { lang: { eq: $lang } }) {
      nodes {
        productsCategoryList {
          allProductsText
          allProductsLink {
            url
          }
          productsTitle
          productTitleShort
          productTitleLong
          productsDescription
          productContent {
            boxTitle
            productButton {
              ariaLabel
              link {
                url
              }
              text
              withReverse
            }
            productImage {
              fallbackUrl
              fluid {
                base64
                originalImg
                srcSet
              }
              variants {
                alias
                fallbackQuery
                type
                url
              }
            }
            productImageAlt
          }
        }
        recommendations {
          recommendationsTitle
          recommendationsList {
            recommendationsItemTitle
            recommendationsItemImage {
              fallbackUrl
              fluid {
                base64
                originalImg
                srcSet
              }
              variants {
                alias
                fallbackQuery
                type
                url
              }
            }
            recommendationsItemImageAltText
            recommendedProducts {
              name
              url
            }
          }
          learnMoreLabel
        }
        promoPanel {
          videoBanners {
            content
            button {
              ariaLabel
              text
            }
            bannerVariant
            imageAsBackgroundAlt
            withWrapper
            withVideo {
              link
              title
              autoplay
              image {
                fallbackUrl
                fluid {
                  base64
                  originalImg
                  srcSet
                }
                variants {
                  alias
                  fallbackQuery
                  type
                  url
                }
              }
            }
          }
          title
          viewAllButton {
            ariaLabel
            text
            link {
              url
            }
            withReverse
          }
          moreLabelText
          moreLabelTextAriaLabel
          promoPanelBackgroundImage {
            fallbackUrl
            fluid {
              srcSet
              originalImg
              base64
            }
            variants {
              url
              type
              fallbackQuery
              alias
            }
          }
          promoPanelBackgroundImageAlt
        }
        findYourVeetTool {
          findYourVeetIframe
          isFindYourVeetToolVisible
          findYourVeetTitle
          tryInteractId
        }
        mainCarousel {
          carouselItemsDesktop {
            ...umbracoImageWithAlt
          }
          carouselItemsMobile {
            ...umbracoImageWithAlt
          }
        }
      }
    }
    promoBanners: allUmbracoArticles(
      filter: {
        tags: { elemMatch: { id: { in: $promoArticleTags } } }
        langProps: { lang: { eq: $lang } }
      }
      limit: $promoArticlesBannerLimit
    ) {
      nodes {
        title
        url
        articleThumbnailImage {
          fallbackUrl
          fluid {
            srcSet
            originalImg
            base64
          }
          variants {
            url
            type
            fallbackQuery
            alias
          }
        }
        articleImageAlt
      }
    }
  }
`;

export default HomePage;
