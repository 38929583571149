import React, { FC } from 'react';
import classNames from 'classnames';
import UmbracoImage from 'common/Image/UmbracoImage';
import ProductCard from 'common/ProductCard';
import { RecommendationsListItemProps } from '../models';

import './RecommendationsListItem.scss';

const RecommendationsListItem: FC<RecommendationsListItemProps> = ({
  recommendationsListItem: {
    recommendationsItemTitle,
    recommendationsItemImage,
    recommendedProducts,
    recommendationsItemImageAltText,
  },
  learnMoreLabel,
  productNodes,
  active,
  recommendationToggle,
  isActive,
}) => {
  const recommendedProductsValues = recommendedProducts.map(({ url }) => url);
  const filteredProducts = productNodes.filter(({ url }) =>
    recommendedProductsValues.includes(url)
  );

  return (
    <>
      <button
        type="button"
        className={classNames('recommendations-item', {
          'recommendation--active': active,
          'recommendation--hidden': isActive >= 0 && !active,
        })}
        onClick={recommendationToggle}
      >
        <div className="recommendations-item__image">
          {recommendationsItemImage ? (
            <UmbracoImage
              image={recommendationsItemImage}
              alt={recommendationsItemImageAltText}
              objectFit="contain"
            />
          ) : null}
        </div>
        <h2 className="recommendations-item__title">{recommendationsItemTitle}</h2>
      </button>

      {active
        ? filteredProducts.map(
            ({
              productTitle,
              productTitleLong,
              productTitleWithSize,
              productTitleShort,
              productSize,
              url,
              productImage,
              localProductImage,
              productEAN,
              variantProductCards,
            }) => (
              <ProductCard
                key={`${productTitle}-${productImage}`}
                {...{
                  productEAN,
                  productTitle,
                  productTitleLong,
                  productTitleWithSize,
                  productTitleShort,
                  productSize,
                  url,
                  productImage,
                  localProductImage,
                  learnMoreLabel,
                  variantProductCards,
                }}
              />
            )
          )
        : null}
    </>
  );
};

export default RecommendationsListItem;
